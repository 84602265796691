<template>
    <div class="home" id="home">
        <div v-wechat-title="this.title">
            <van-sticky :offset-top="0">
                <div class="page_top">
                    <div class="date_choose">
                        <!-- <i class="icon iconfont iconarrow-left-filling" @click="subtractDate"></i> -->
                        <van-icon name="arrow-left" @click="subtractDate" />
                        <span class="date_info" @click="chooseCheckDay">{{ date || '请选择' }}</span>
                        <van-icon name="arrow" @click="addDate" />
                        <!-- <i class="icon iconfont iconarrow-right-filling" @click="addDate"></i> -->
                    </div>
                    <van-search class="search_div" input-align="center" left-icon="" background="#F8F7FC"
                        @search="qrypage = 1; listLoading = false; finish = false; reLoad()" v-model="keyword"
                        placeholder="预约编号/患者姓名/手机号码">
                        <van-icon name="close" @click="() => keyword = ''" v-if="keyword" />
                    </van-search>
                </div>
            </van-sticky>


            <van-calendar v-model="showCalendar" :default-date="defaultDate" :min-date="minDate" :show-confirm="false"
                @confirm="onConfirmCalendar" />

            <van-tabs v-model="qrystatus" :offset-top="62" @change="changeTab" class="status_div" color="#E69F12"
                title-active-color="#E69F12" sticky swipeable>

                <van-tab title="门诊预约" name="0">

                </van-tab>

                <van-tab title="理疗预约" name="1">

                </van-tab>

                <van-tab title="体检预约" name="3">

                </van-tab>
                <div class="treat_main_list" :class="{ 'bai': count <= 0 }">
                    <van-list v-model="listLoading" :finished="finished" @load="reLoad" :offset="30"
                        :immediate-check="false" v-if="list.length > 0" finished-text="加载完成">
                        <div class="one" v-for="(item, index) in list" :key="item.serviceid">

                            <div class="name_box">
                                <div class="name">
                                    <span class="username">{{ item.username }}</span>
                                    <span v-if="item.seetypename == '复' || item.seetypename == 'W'" class="seetypename_f">{{
                                        item.seetypename }}</span>
                                    <span v-if="item.seetypename == '初'" class="seetypename_c">{{ item.seetypename }}</span>
                                </div>
                                <div class="ex_box">

                                    <div class="appointmentnum" v-if="item.appointmentnum">{{ item.appointmentnum }}</div>
                                    <div class="appointment" @click="medical(item.userid, item.customerid)">
                                        <span>查看病历</span> <i class="icon iconfont iconsanjiaoyou"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="name_box line">
                                <div class="flex">
                                    <div class="user_info">{{ item.sex }}</div>
                                    <div class="user_info">{{ item.age }}岁</div>
                                    <div class="user_info">{{ item.phone }}</div>
                                </div>
                                <div class="flex">
                                    <div v-if="item.appointmentseq && item.wait != 0"
                                        @click="operate(3, item.recordid, index)" class="mini_bt">上移</div>
                                    <div v-if="item.appointmentseq && (item.seestatus == 1 || item.seestatus == 0)"
                                        @click="operate(4, item.recordid, index)" class="mini_bt">下移</div>
                                </div>

                            </div>
                            <div class="name_box">
                                <div>预约时间</div>
                                <div>{{ item.time ? item.time : '无' }}</div>
                            </div>
                            <div class="name_box">
                                <div>预约医生</div>
                                <div>{{ item.dispname }}</div>
                            </div>
                            <div class="name_box" v-if="item.execstatusname != '就诊中'">
                                <div>等待人数</div>
                                <div>{{ item.seestatus == 2 && qrystatus == 0 ? '就诊中' : item.wait }}</div>
                            </div>
                            <div class="name_box" v-if="qrystatus != 0">
                                <div>就诊状态</div>
                                <div v-if="item.seestatus == 0">未报到</div>
                                <div v-else-if="item.seestatus == 1">候诊中</div>
                                <div v-else-if="item.seestatus == 2"> 就诊中 </div>
                                <div v-else-if="item.seestatus == 3">已完成</div>
                                <div v-else>{{ item.execstatusname }}</div>
                            </div>
                            <div class="name_box" v-if="item.appointmentseq">
                                <div>报到号</div>
                                <div>{{ item.appointmentseq }}</div>
                            </div>
                            <div class="flex top10">
                                <van-button v-if="item.seestatus == 1" @click="operate(2, item.recordid, index)" round
                                    type="warning">取消报到</van-button>
                                <van-button v-if="item.seestatus == 0" @click="operate(1, item.recordid, index)" round
                                    type="success">报到</van-button>
                                <van-button v-if="item.seestatus == 1 || item.seestatus == 0"
                                    @click="detailCtrl(item.recordid)" round type="success">预录入</van-button>
                                <van-button v-if="(item.seestatus == 1 || item.seestatus == 0)"
                                    @click="editDoctor(item.recordid, item.dispname, item.doctorid, item.userid, item.customerid, item.username, item.planid)"
                                    round type="success">预约调整</van-button>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        当天无预约数据
                    </div>
                </div>
            </van-tabs>
            <van-dialog v-model="show" :close-on-popstate="true" showCancelButton title="预约调整" @confirm="subDoctor();"
                @cancel="treatitem = {}" :before-close="onBeforeClose">
                <van-cell :border="false" title="诊所名称：" @click="showClinic = true" :value="treatitem.clinicname" is-link />
                <van-cell :border="false" title="就诊人：" @click="showDialog = true; setempty = true"
                    :value="treatitem.customername" is-link />
                <van-cell :border="false" @click="showSchedulingCalendar = true" title="排班日期："
                    :value="schedulingDate || '请选择'" is-link />
                <van-cell :border="false" title="医生姓名：" @click="showPicker = true" is-link
                    :value="treatitem.doctor ? treatitem.doctor : doctor" />

                <van-cell :border="false" title="排班时间：" :value="timeList.length == 0 ? '暂无排班时间' : ''">

                </van-cell>
                <van-radio-group v-model="planid" direction="horizontal" class="radio_box">
                    <van-radio v-for="item in timeList" :key="item.planid" :name="item.planid">{{ item.starttime
                    }}~{{ item.endtime }}</van-radio>
                </van-radio-group>
            </van-dialog>
            <van-popup v-model="showPicker" round position="bottom">
                <van-picker title="请选择医生" show-toolbar :columns="doctorList" :default-index="defaultIndex"
                    @cancel="showPicker = false" @confirm="onConfirmDoctor"><template #option="item">{{ item.doctor
                    }}</template>
                </van-picker>
            </van-popup>
            <van-popup v-model="showClinic" round position="bottom">
                <van-picker title="请选择诊所" show-toolbar :columns="clinicList"
                    :default-index="clinicList.findIndex((i) => i.clinicid == treatitem.clinicid)"
                    @cancel="showClinic = false" @confirm="onConfirmClinic"><template #option="item">{{ item.clinicname
                    }}</template>
                </van-picker>
            </van-popup>
        </div>
        <van-dialog v-model="showDialog" :close-on-popstate="true" @confirm="confirm">
            <van-sticky :offset-top="0">
                <div class="title">
                    <div>选择患者</div>

                </div>
                <van-icon class="close_icon" name="cross" @click="close()" />
            </van-sticky>
            <div>
                <patientList v-if="showDialog" @patientData="patientFromChild" :offset="41" :planid="planid"
                    :qrytype="qrystatus" :p_clinicid="treatitem.clinicid" :p_checked="checked" />
            </div>
        </van-dialog>
        <van-calendar v-model="showSchedulingCalendar" :default-date="new Date(schedulingDate)"
            :min-date="schedulingMinDate" :show-confirm="false" @confirm="onConfirmSchedulingCalendar" />
    </div>
</template>

<script>
import { wxappointmentcheckininfoqry, wxappointmentopr, wxappointmentplaninfoqry, wxqryclinic, wxappointmentplanbydoctor, wxmodifyappointmentinfo } from '@/api/medical'
import { mapGetters } from 'vuex'
// import Vue from 'vue';
import { Toast, Calendar } from 'vant';
import Vue from 'vue';
import dayjs from 'dayjs';
import patientList from '../patientList/index.vue'
Vue.use(Calendar);

export default {
    computed: {
        ...mapGetters([
            'username', 'clinicid', 'isrefresh', 'isclose'
        ]),
        // clinicIndex (clinicid){
        //     // return Array.prototype.findIndex.call(this.clinicList, ((i)=>{return clinicid==treatitem.clinicid}));
        //     return 1;
        // }
    },
    components: { patientList },
    data() {
        return {
            qrystatus: '0',
            list: [],
            // 无限加载
            listLoading: false,
            finished: false,
            keyword: '',
            wait: 0,
            count: 0,
            execute: 0,
            finish: 0,
            minDate: new Date(2020, 0, 1),
            schedulingMinDate: new Date(),
            defaultDate: new Date(),
            date: '',
            showCalendar: false,
            title: '报到分诊台',
            show: false,
            doctor: '',
            recordid: '',
            showPicker: false,
            doctorList: [],
            treatitem: {},
            noDoctor: true,
            qrypage: 1,
            schedulingDate: '',
            showSchedulingCalendar: false,
            schedulingTime: '',
            showClinic: false,
            clinicList: [],
            defaultIndex: 0,
            timeList: [],
            showDialog: false,
            checked: 0,
            p_customername: '',
            p_customerid: '',
            p_userid: '',
            p_checked: 0,
            setempty: true,
            planid: '',
            isscroll: 1,
            scrollTop: 0,
            p_list: [],
            p_index: 0,
            p_planid: ''
        }
    },
    mounted() {
        this.getCurDate()
        Toast({
            message: '加载中...',
            type: 'loading',
            duration: 0
        })
        this.reLoad()
    },
    activated() {
        this.showDialog = false
        this.$store.dispatch('changeNav', '0')
        this.$store.dispatch('hideOrShowNav', false)
        if (this.isrefresh == '1' && !this.listLoading) {
            this.$store.dispatch('changeIsRefresh', '0')
            this.treatitem.clinicid = this.clinicid
            this.date = dayjs().format('YYYY-MM-DD')
            this.qrystatus = 0
            this.keyword = '';
            this.qrypage = 1
            this.listLoading = false
            this.finish = false
            // this.reLoad();
            this.show = false
        } else {
            document.getElementsByClassName('home')[0].scrollTop = this.scrollTop

        }

    },
    beforeRouteLeave(to, from, next) {
        this.scrollTop = document.getElementsByClassName('home')[0].scrollTop
        next()
    },

    // //进入该页面时，用之前保存的滚动位置赋值

    methods: {
        medical(useuserid, usecustomerid) {
            this.$store.dispatch('changeIsRefresh', '1')
            this.$router.push({
                path: '/medicalPage',
                query: {
                    userid: useuserid,
                    customerid: usecustomerid,
                }
            })
        },
        goBack() {
            window.WeixinJSBridge.call('closeWindow')
        },
        getCurDate() {
            const nowDate = new Date();
            this.formatDate(nowDate)
        },
        addDate() {
            var d = new Date(this.date);
            d.setDate(d.getDate() + 1);
            this.formatDate(d)
            this.listLoading = false
            this.finish = false
            this.qrypage = 1
            this.reLoad()

        },
        subtractDate() {
            var d = new Date(this.date);
            d.setDate(d.getDate() - 1);
            this.formatDate(d)
            this.listLoading = false
            this.finish = false
            this.qrypage = 1
            this.reLoad()

        },
        formatDate(d) {
            const curDate = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                date: d.getDate(),
            }
            const newmonth = curDate.month >= 10 ? curDate.month : '0' + curDate.month
            const day = curDate.date >= 10 ? curDate.date : '0' + curDate.date
            this.date = curDate.year + '-' + newmonth + '-' + day
            this.defaultDate = new Date(this.date);
        },
        chooseCheckDay() {
            this.showCalendar = true
        },
        onConfirmCalendar(date) {
            this.showCalendar = false
            this.formatDate(date);
            Toast({
                message: '加载中...',
                type: 'loading',
                duration: 0
            })
            this.qrypage = 1
            this.listLoading = false
            this.finish = false
            this.reLoad()
        },
        onConfirmSchedulingCalendar(d) {
            this.showSchedulingCalendar = false
            const curDate = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                date: d.getDate(),
            }
            const newmonth = curDate.month >= 10 ? curDate.month : '0' + curDate.month
            const day = curDate.date >= 10 ? curDate.date : '0' + curDate.date
            this.schedulingDate = curDate.year + '-' + newmonth + '-' + day
            this.getdoctorroomList()
            this.getList()
        },
        changeRouter(url) {
            if (url) {
                this.$router.push(url)
            }
        },

        changeTab() {
            if (this.isload) {
                return
            }

            Toast({
                message: '加载中...',
                type: 'loading',
                duration: 0
            })
            this.qrypage = 1
            this.listLoading = false
            this.finish = false
            this.reLoad()
        },

        reLoad() {
            if (this.clinicid == null || this.clinicid == '' || this.clinicid == undefined
                || this.username == null || this.username == '' || this.username == undefined) {
                Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: '未登录或未选择诊所，页面关闭',
                });
                let second = 1;
                const timer = setInterval(() => {
                    second--;
                    if (!second) {
                        clearInterval(timer);
                        // 手动清除 Toast
                        Toast.clear();
                        this.goBack()
                    }
                }, 1000);
                return;

            }

            this.listLoading = true


            let data = {
                username: this.username,
                qrydate: this.date,
                keywords: this.keyword,
                clinicid: this.clinicid,
                qrytype: this.qrystatus,
                qrypage: this.qrypage
            }

            wxappointmentcheckininfoqry(data).then(response => {
                if (Toast) {
                    Toast.clear()
                }
                this.scrollTop = 0
                if (this.isscroll) {
                    this.scrollTop = document.getElementsByClassName('home')[0].scrollTop
                }
                this.isscroll = 0
                if (response.response_body && response.response_body.list) {
                    response.response_body.list.forEach((item) => {
                        item.phone = item.phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
                    })

                    this.count = parseInt(response.response_body.count)
                    if (this.qrypage == 1) {
                        this.list = response.response_body.list
                        document.getElementsByClassName('home')[0].scrollTop = this.scrollTop;

                    } else {
                        this.list = [...this.list, ...response.response_body.list]
                    }
                    if (response.response_body.list.length < 10) {
                        this.finished = true;
                    } else {
                        this.finished = false;
                    }


                }
                this.qrypage++
                this.listLoading = false
            }).catch(error => {
                this.listLoading = false
            })



        },
        // async replaceList() {

        //     let data = {
        //         username: this.username,
        //         qrydate: this.date,
        //         keywords: this.keyword,
        //         clinicid: this.clinicid,
        //         qrytype: this.qrystatus,
        //         qrypage: this.qrypage
        //     }
        //     await wxappointmentcheckininfoqry(data).then(response => {
        //         this.scrollTop = 0
        //         if (this.isscroll) {
        //             this.scrollTop = document.getElementsByClassName('home')[0].scrollTop
        //         }
        //         this.isscroll = 0
        //         if (response.response_body && response.response_body.list) {
        //             response.response_body.list.forEach((item) => {
        //                 item.phone = item.phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
        //             })

        //             if (this.qrypage == 1) {
        //                 this.p_list = response.response_body.list
        //                 document.getElementsByClassName('home')[0].scrollTop = this.scrollTop;

        //             } else {
        //                 this.p_list = [...this.p_list, ...response.response_body.list]
        //             }
        //             if (response.response_body.list.length < 10) {
        //                 this.finished = true;
        //             } else {
        //                 this.finished = false;
        //             }


        //         }
        //         this.qrypage++
        //         if (this.p_index > this.p_list.length && !this.finished) {
        //             this.replaceList()
        //         } else {
        //             this.list = this.p_list
        //             this.listLoading = false

        //             Toast.success({
        //                 message: '操作成功',
        //                 forbidClick: true,
        //             });
        //             setTimeout(() => {
        //                 Toast.clear()
        //             }, 1000)

        //             return
        //         }
        //     }).catch(error => {

        //     })

        // },
        // 预录入
        detailCtrl(recordid) {
            this.$store.dispatch('changeIsRefresh', '0')
            this.$router.push({
                path: '/appointmentDetail',
                query: {
                    recordid: recordid

                }
            })
        },
        // 1：报到，2：取消报到，3：上移，4：下移，5：修改医生
        operate(oprtype, recordid, index) {
            Toast({
                message: '操作中...',
                type: 'loading',
                forbidClick: true,
                duration: 0
            })
            let sub_data = {
                username: this.username,
                recordid: recordid,
                oprtype: oprtype
            }
            wxappointmentopr(sub_data).then(response => {

            }).then(() => {

                this.qrypage = 1
                this.listLoading = true
                this.finish = false
                // this.isscroll = 1
                this.p_index = index
                this.reLoad()

            }).catch((error) => {

            });
        },
        patientFromChild(data) {
            this.p_customerid = data.customerid
            this.p_customername = data.customername
            this.p_userid = data.userid
            this.p_checked = data.checked
        },
        close(){
            this.showDialog = false; 
            this.p_customerid=this.treatitem.customerid;
            this.p_customername =this.treatitem.customername;
            this.p_userid=this.treatitem.userid;
            this.p_checked=this.checked
        },
        editDoctor(recordid, doctorname, doctorid, userid, customerid, username, planid) {
            this.show = true
            this.planid = planid
            this.p_planid = planid
            this.recordid = recordid
            this.treatitem.doctor = doctorname
            this.treatitem.userid = userid
            this.treatitem.customerid = customerid
            this.checked = userid + '-' + customerid
            this.treatitem.customername = username
            this.treatitem.username = doctorid
            this.schedulingDate = this.date
            this.treatitem.clinicid = sessionStorage.getItem('ClinicId')
            this.treatitem.clinicname = sessionStorage.getItem('ClinicName')

            this.getclinicList()
            this.getdoctorroomList()
            this.getList()
        },
        onConfirmDoctor(val) {
            this.treatitem.doctor = val.doctor
            this.treatitem.username = val.username
            this.showPicker = false
            this.getList()
        },
        onConfirmClinic(val) {
            this.treatitem.clinicid = val.clinicid
            this.treatitem.clinicname = val.clinicname
            this.showClinic = false
            this.getdoctorroomList()
            this.getList()
        },
        confirm() {
            this.setempty = true
            if (this.p_userid != '') {
                this.treatitem.customerid = this.p_customerid;
                this.treatitem.customername = this.p_customername;
                this.treatitem.userid = this.p_userid;
                this.checked = this.p_checked
            }

        },
        subDoctor() {
            if (!this.treatitem.clinicid) {
                Toast.fail('请选择诊所');
                return;
            }
            if (!this.treatitem.userid) {
                Toast.fail('请选择就诊人');
                return;
            }
            if (!this.treatitem.username) {
                Toast.fail('请选择医生');
                return;
            }

            if (!this.schedulingDate) {
                Toast.fail('请选择排班日期');
                return;
            }
            if (!this.planid) {
                Toast.fail('请选择排班时间');
                return;
            }
            let planIndex = this.timeList.findIndex((item) => item.planid == this.planid)
            if (this.timeList.length == 0 || planIndex == -1) {
                Toast.fail('请选择排班时间');
                return;
            }
            let sub_data = {
                username: this.username,
                recordid: this.recordid,
                planid: this.planid,
                userid: this.treatitem.userid,
                customerid: this.treatitem.customerid,
                clinicid: this.treatitem.clinicid,
                doctorid: this.treatitem.username,
                date: this.schedulingDate
            }
            wxmodifyappointmentinfo(sub_data).then(response => {

            }).then(() => {
                this.show = false

                Toast({
                    message: '调整成功',
                    type: 'success',
                    forbidClick: true,
                    duration: 0
                })
                setTimeout(() => {
                    this.treatitem = {}
                    this.qrypage = 1
                    this.listLoading = false
                    this.finish = false
                    this.isscroll = 1
                    this.reLoad()
                }, 2000)

            }).catch((error) => {

                // this.show = false
            });
        },
        onBeforeClose(action, done) {
            // new Promise((resolve) => {
            if (action === 'confirm') {
                console.log(this.noDoctor);
                if (this.noDoctor) {
                    done(false);
                }
                done(true);
            } else {
                // 拦截取消操作
                done(true);
            }
            //  })

        },
        // 查询医生
        getdoctorroomList() {
            let data = {
                username: this.username,
                clinicid: this.treatitem.clinicid,
                qrydate: this.schedulingDate,
                keywords: '',
                qrytype: this.qrystatus,
                qrypage: 0
            }
            wxappointmentplaninfoqry(data).then(response => {
                this.doctorList = []
                let i = 0
                this.defaultIndex = -1
                response.response_body.list.map((item) => {

                    if (this.treatitem.username == item.username) {
                        this.defaultIndex = i
                    }
                    // if (item.surplusnum > 0) {
                    this.doctorList.push({ doctor: item.dispname, username: item.username })
                    i++
                    // }

                })
                if (this.defaultIndex == -1) {
                    this.doctor = ''
                    this.treatitem.doctor = ''
                    this.treatitem.username = ''
                }
            })
        },
        getclinicList() {
            let data = {
                username: this.username
            }
            wxqryclinic(data).then(response => {
                this.clinicList = response.response_body.cliniclist
            })
        },
        getList() {
            if (this.treatitem.username == '' || !this.treatitem.username) {
                return;
            }
            let data = {
                username: this.username,
                qrydate: this.schedulingDate,
                doctorname: this.treatitem.username,
                clinicid: this.treatitem.clinicid,
                qrytype: this.qrystatus,
            }

            wxappointmentplanbydoctor(data).then(response => {
                //   this.week_text = response.response_body.week
                //   this.clinicname = response.response_body.clinicname
                this.timeList = []
                response.response_body.list.forEach((item) => {
                    if ((item.surplusnum > 0 && item.overtime == "false") || this.p_planid == item.planid) {
                        this.timeList.push(item)
                    }
                })
            })

        },

    },
}
</script>

<style lang="scss" scoped>
.home {

    background: #fafafa;
    height: 100vh;
    overflow: auto;

    .van-info {
        top: 2px;
        right: -8px;
    }

    .van-search__content {
        .van-cell {
            background-color: unset !important;
            border-radius: 1.90667rem;
            padding: 0 !important;
        }
    }

    .bai {
        background-color: #ffffff;
    }

    .btnimg {
        width: 50px;
        height: 50px;
        position: fixed;
        right: 10px;
        bottom: 200px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .treat_main_list .one {
        background: linear-gradient(180deg, rgba(207, 140, 87, 0.2) 0%, rgba(255, 249, 244, 0) 20%);
    }

    .photo {
        width: 100px;
        height: 120px;
        border-radius: 10px;
        margin-right: 10px;
    }

    // .one{
    //     display: flex;
    // }
    .name {
        font-size: 18px;
        font-weight: 600;
        display: flex;
    }

    :deep .right .name {

        width: 2rem !important;
    }

    .surplusnum {
        color: red;
    }

    .user_info {
        color: #999995;
        margin-right: 4px;
        line-height: 20px;
    }

    .date_choose {
        border: 0.02667rem solid #dfdcdc;
        padding: 10px 2px;
        width: 150px;
        border-radius: 3.48rem
    }

    .search_div {
        width: calc(100% - 160px);
    }

    :deep .van-field__control--center {
        text-align: left;
    }

    :deep .van-search__content {
        padding-left: 0rem;
        border-radius: 1rem;
    }

    .seetypename_f {
        color: rgb(230, 159, 18);
        padding: 2px;
        border-radius: 5px;
        border: rgb(230, 159, 18) solid 1px;
        font-size: 14px;
        font-weight: 400;
        margin-left: 5px;
    }

    .seetypename_c {
        color: rgb(133, 142, 228);
        padding: 2px;
        border-radius: 5px;
        border: rgb(133, 142, 228) solid 1px;
        font-size: 14px;
        font-weight: 400;
        margin-left: 5px;
    }

    .ex_box {
        display: flex;
        align-items: center;
        // color: rgb(230, 159, 18);
        color: #999999;
        justify-items: center;

        i {
            color: #BBBBBB;
            font-style: normal;
            font-size: 0.64rem;
            margin-left: 0.05333rem;

        }
    }

    .appointmentnum {
        color: rgb(230, 159, 18);
        background-color: #fbeec8;
        padding: 2px 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .name_box {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mini_bt {
        color: #5C7BFF;
        margin-right: 5px;
    }

    .line {
        border-bottom: 1px solid #dfdcdc;
        padding-bottom: 8px;
    }

    :deep .van-button--round {
        background-color: #CF8C57;
        width: 100px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        color: #fff;
        border-radius: 21px;
        margin: 0px 5px;
    }

    :deep .van-button--warning {
        background-color: #bfbaba !important;
        border: 0.02667rem solid #d3d3d3
    }

    .top10 {
        margin-top: 10px;
    }

    :deep .van-cell__right-icon {
        line-height: 25px;
    }

    :deep .van-cell {
        background-color: #ffffff;
        padding: 10px 20px;
    }

    .appointment {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .username {
        max-width: 3rem;
        display: block !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    :deep .van-radio {
        margin-bottom: 5px;
    }

    :deep .van-radio__label {
        color: #969799;
    }

    .radio_box {
        width: 100%;
        padding: 5px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    :deep .van-dialog__content {
        max-height: 450px;
        overflow: auto;
    }

    :deep .van-dialog {
        width: 90%;
    }

    :deep .van-dialog__header {
        padding: 10px 0px !important;
    }

    :deep .keyword_input {
        .van-sticky--fixed {
            top: 41px;
            z-index: 1;
            margin: 0px 4px;
        }
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 10px 10px;

    }

    .close_icon {
        color: #969799;
        position: absolute !important;
        top: 11px;
        right: 13px;
        font-size: 20px;
    }

    :deep .btnimg {
        display: none;
    }

    :deep .treat_main_list {
        width: 100%;

        .van-radio {
            overflow: visible;

        }
    }

    :deep .van-radio {
        display: flex;
    }

    :deep .detail_info {
        display: none !important;
    }

    :deep .van-cell {
        background-color: transparent;
    }

    :deep .text {
        width: 100%;
        margin-top: 0px !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-size: 0.34667rem;
        color: #999999;
        margin-bottom: 0.16667rem;
        padding-bottom: 0.16667rem;
        border-bottom: 0.02667rem solid #ECEEED;
    }

    :deep .van-button {
        background-color: #CF8C57;
        width: 4rem;
        height: 1.01333rem;
        text-align: center;
        line-height: 1.01333rem;
        color: #fff;
        border-radius: 0.56rem;
        flex: unset;
    }

    :deep .van-dialog__footer {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    :deep .van-dialog__cancel {
        background-color: #bfbaba !important;
        border: 0.02667rem solid #d3d3d3;
        margin-right: 10px;
    }

    :deep :van-hairline--left {
        margin-left: 10px;
    }
}

:deep .name_appointment {
    margin-bottom: 0rem !important;
    height: 0.8rem;
}
</style>