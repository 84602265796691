<template>
    <div>
        <van-sticky :offset-top="offset ? offset : 0" class="keyword_input">
            <div class="page_top">
                <van-search class="search_div" background="#F8F7FC" v-model="keyword" @search="onSearch(keyword)"
                    placeholder="患者姓名或手机号或微信昵称搜索">
                    <van-icon name="close" @click="() => keyword = ''" v-if="keyword" />
                </van-search>
            </div>
        </van-sticky>
        <van-overlay :show="showLoading">
            <div style="width: 100%;height: 100%;;display: flex;align-items: center;justify-content: center;">
                <van-loading size="24px" v-if="showLoading">加载中...</van-loading>
            </div>

        </van-overlay>
        <div class="treat_main_list">
            <van-radio-group v-model="checked">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" v-if="patientList.length > 0"
                    :offset="30" :immediate-check="false" @load="onLoad">
                    <div class="one" v-for="(item, index) in patientList" :key="index"
                        @click="checked = item.userid+'-'+item.customerid; sendDataToParent(item.userid, item.customerid, item.customername)">

                        <div class="name_appointment">
                            <div class="left text-overflow">{{ item.customername }}</div>
                            <div>
                                <div class="right "
                                    @click="$router.push({ path: '/patientDetail', query: { customerid: item.customerid, userid: item.userid, keyword: item.customername } })">
                                    <div class="detail_info">
                                        <span>详细资料</span>
                                        <i class="icon iconfont iconsanjiaoyou"></i>
                                    </div>
                                </div>
                                <div class="right">
                                    <van-radio :name="item.userid+'-'+item.customerid"
                                        @click="sendDataToParent(item.userid, item.customerid, item.customername,item.username)" />
                                </div>
                            </div>

                        </div>
                        <div class="text">
                            <span v-if="item.nick_name">{{ item.nick_name }}</span>
                            <span>{{ item.sex }}</span>
                            <span> {{ item.age }}岁</span>
                            <span>{{ item.customerphone }}</span>
                        </div>
                        <div class="title_top">
                            <div class="left">主账号</div>
                            <div class="right">
                                <div class="name">{{ item.username }}</div> /{{
                                    (item.userphone).replace(/(\d{3})\d{4}(\d{4})/,
                                        "$1****$2") }}
                            </div>
                        </div>
                        <div class="title_top">
                            <div class="left">注册时间</div>
                            <div class="right">{{ item.registertime }}</div>
                        </div>

                        <!--
                <div style="display: flex;justify-content: center;">
                    <div @click="chooseOther(item)" style="background-color: #CF8C57;width: 100px;height: 38px;text-align: center;line-height: 38px;color: #fff;border-radius: 21px;">选择</div>
                </div> -->
                    </div>
                </van-list>

                <div class="has_no_data" v-else>
                    当前无患者数据
                </div>
            </van-radio-group>
        </div>

        <div class="btnimg" @click="$router.push('/addUser')">
            <img src="@/assets/adduser.png" alt="">
        </div>
    </div>
</template>
<script>
import { wxtriagecustomerqry,wxnoappointmentcustomerinfoqry } from '@/api/medical'
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'username', 'dispname', 'clinicid', 'isrefresh'
        ]),
    },
    props: ['offset', 'planid','qrytype','p_clinicid','p_checked'],
    data() {
        return {
            keyword: '',
            patientList: [],
            finished: false,
            loading: false,
            page: 0,
            showLoading: true,
            checked: ''
        }
    },
    created() {
        this.$store.dispatch('hideOrShowNav', false)
        this.onLoad()
        this.keyword = ''
    },

    activated() {

        this.$store.dispatch('hideOrShowNav', false)

        if ((this.isrefresh == '1' && !this.loading)||this.planid) {
            this.$store.dispatch('changeIsRefresh', '0')
            this.page = 0
            this.patientList = []
            this.keyword = ''
            this.finished = false
            this.loading = false
            this.onLoad()
        }
    },
    methods: {
        onLoad() {
            this.showLoading = true
            this.page += 1
            let data = {
                username: this.username,
                qrypage: this.page
            }
            // 选择就诊人时，需要调用另一个接口
            if (this.planid) {
                data.planid = this.planid
                data.keywords =  this.keyword
                data.qrytype = this.qrytype,
                data.clinicid = this.p_clinicid
                wxnoappointmentcustomerinfoqry(data).then(response => {
                    this.loading = false
                    this.showLoading = false
                    this.checked = this.p_checked
                    console.log( this.p_checked)
                    if (response.response_body.count > 0) {

                        this.patientList = [...this.patientList, ...response.response_body.list] || []

                        if (response.response_body.list.length < 10) {

                            this.finished = true
                        }
                    } else {
                        this.finished = true
                        this.patientList = []
                    }

                }).catch(error => {
                    this.loading = false
                    this.showLoading = false
                })
            } else {
                data.keyword = this.keyword
                wxtriagecustomerqry(data).then(response => {
                    this.loading = false
                    this.showLoading = false

                    if (response.response_body.count > 0) {

                        this.patientList = [...this.patientList, ...response.response_body.list] || []

                        if (this.page >= response.response_body.pages) {

                            this.finished = true
                        }
                    } else {
                        this.finished = true
                        this.patientList = []
                    }

                }).catch(error => {
                    this.loading = false
                    this.showLoading = false
                })

            }
        },
        chooseDetail(item) {
            localStorage.setItem('UserInfoDetail', JSON.stringify(item))
            this.$router.push({ path: '/patientDetail', query: { username: item.customername, customerid: item.customerid } })
        },
        onSearch(key) {
            this.page = 0
            this.patientList = []
            this.finished = false
            this.loading = false
            this.keyword = key
            this.onLoad()

        },
        sendDataToParent(userid, customerid, customername) {
            this.$emit('patientData', { userid: userid, customerid: customerid, customername: customername,checked:this.checked});
        }
    }
}
</script>
<style lang="scss" scoped>
.treat_main_list {
    background-color: #fff;

    .one {
        background: linear-gradient(180deg, rgba(207, 140, 87, 0.2) 0%, rgba(255, 249, 244, 0) 20%) !important;
    }

    .text {
        margin: 10px 0;
        line-height: 22px;
        text-align: left;

        span {
            margin-right: 10px;
        }
    }

    .title_top {
        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .name {
                width: 100px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .name_appointment {
        .left {
            width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.btnimg {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 10px;
    bottom: 200px;

    img {
        width: 100%;
        height: 100%;
    }
}

.page_top .search_div {
    width: 100%;
}

.van-search__content {
    padding-left: unset;
}

.keyword_input {
    width: 100%;
}

:deep .van-radio {
    display: none;
}

.text-overflow {
    white-space: nowrap;
    /* 不换行 */
    overflow: hidden;
    /* 隐藏超出的内容 */
    text-overflow: ellipsis;
    /* 用省略号表示被隐藏的部分 */
    max-width: 200px;
    /* 设置最大宽度以限制文本的显示长度 */
}</style>